/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable no-alert */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Alert ,Col, Image} from 'react-bootstrap';
import axios from 'axios';
import Topo from './components/topo';
import Rodape from './components/rodape';




class Cadastrar extends Component {
 constructor(props) {
    super(props);
      state = {
      crm: '',
      senha: '',
      dados: [],
      botao: 'Validar',
      cor: 'primary',
      camposenha: false,
  }
}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

  }

  handleSubmit = (e) => {
    axios.get('https://mgabr.com/apiapp/demostracao/validacrm.php', {
      params: {
        crm: this.state.crm
      }
    }
    )
      .then(res => {
        const dados = res.data;
        this.setState({ dados });
     
        if (dados.length === 0) {
          this.setState({ botao: 'Você não tem acesso' })
          this.setState({ cor: 'danger' })
        } else {
          this.setState({ botao: 'Cadastrar' })
          this.setState({ cor: 'success' })
          this.setState({ camposenha: true })
        }
      });

    this.state.dados.map((post) => {
      const { nome, especialidade } = post;

      sessionStorage.setItem('@masterclinicapwa/login', 'Logado');
      sessionStorage.setItem('@masterclinicapwa/profissional', nome);
      sessionStorage.setItem('@masterclinicapwa/especialidade', especialidade);
      this.props.history.push('/dashboard')


    })

    e.preventDefault();
  }
  
  cadastrar = (e) => {
    axios.get('https://mgabr.com/apiapp/demostracao/criarusuario.php', {
      params: {
        crm: this.state.crm,
        senha: this.state.senha
      }
    }
    )
      .then(res => {
        const dados = res.data;
        this.setState({ dados });

        if (dados.length === 0) {
          this.setState({ botao: 'Ocorreu um erro' })
          this.setState({ cor: 'danger' })
        } else {
          if(res.data === 'ALTERADO'){
            alert("Usuário Alterado com Sucesso!")
          }else if(res.data ==='CADASTRADO'){
            alert("Usuário Cadastrado com Sucesso!")
          }else{
            alert("Houve um erro!")
          }
          
          this.props.history.push('/login')
        }

      });
    e.preventDefault();
  }



  render() {
    const entrar = this.state.botao
    return (

      <div>
        <Topo />
        <Container className="mt-4">
        <Row>    
        <Col className="text-center titulo mt-2">Acesso Restrito</Col>
        </Row>
        <Row className="mt-2 bg-white justify-content-md-center">           
          <Col className="somenteDesk p-4">
          <Image src={require('./medicos.jpg')} className="imagem" />
          </Col>
          <Col>
          <Alert variant="warning mt-4">{this.state.camposenha ? ('Cadastre uma senha'):("Consulte seu CRM")}</Alert>
          {this.state.camposenha ? 
          (
            <Form onSubmit={this.cadastrar} >
              <Form.Group controlId="crm">
                <Form.Label>CRM</Form.Label>
                <Form.Control name="crm" type="text" onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="senha">
                <Form.Label>Senha</Form.Label>
                <Form.Control name="senha" type="password" onChange={this.handleChange} />
              </Form.Group>
              <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
            </Form>
            ) 
            :
            (            
            <Form onSubmit={this.handleSubmit} >
                <Form.Group controlId="crm">
                  <Form.Label>CRM</Form.Label>
                  <Form.Control name="crm" type="text" onChange={this.handleChange} />
                </Form.Group>
               
                <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
              </Form>)}
              </Col>
              </Row>
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default Cadastrar;
