/* eslint-disable eqeqeq */
/* eslint-disable no-alert */
/* eslint-disable react/sort-comp */
/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button, Spinner, Form, Row, Col} from 'react-bootstrap';
import Topo from './components/topo';
import Rodape from './components/rodape';
import Back from "./components/back";

class Convenio extends Component {
  state = {
    convenios: [],
    loading: false,
    forma: '',
    mostraConvenios: false,
    convenio: '0',
    mostraPlanos: false,
    plano: '0',    
  }

  selecionaForma = (e) => {    
    this.setState({forma: e.target.value}); 


    const prof = sessionStorage.getItem('@masterclinicapwa/profissional')
    
    axios.get(`https://mgabr.com/apiapp/endoserv/apiconvenios.php?prof=${prof}`)
      .then(res => {
        const convenios = res.data;
        this.setState({ convenios });
        console.log(convenios);

        if(this.state.forma == 1){
 
          this.setState({ mostraConvenios: true });
        }
        if(this.state.forma == 2){
          this.setState({convenio: 1});    
        }       
        
      });
  }

  selecionaConvenio = (e) => {
    this.setState({convenio: e.target.value});    
    axios.get(`https://mgabr.com/apiapp/endoserv/apiplanos.php/?codconv=${e.target.value}`)

      .then(res => {
        const planos = res.data;

        this.setState({ planos });
        this.setState({ mostraPlanos: true });               
      });
  }

  selecionaPlano = (e) => {
    this.setState({plano: e.target.value});    
  }

 
  handleSubmit =(e) => {
    sessionStorage.setItem('@masterclinicapwa/forma', this.state.forma);
    sessionStorage.setItem('@masterclinicapwa/convenio', this.state.convenio);
    sessionStorage.setItem('@masterclinicapwa/plano', this.state.plano);
    if(this.state.forma === '' && this.state.convenio ===''){
      alert("Selecione uma Opção")
    }
    else if(this.state.forma === 1 && this.state.convenio ===''){
      alert("Selecione um Convênio")
    }else if(this.state.forma === 1 && this.state.plano === ''){
      alert("Selecione um Plano")
    }else{
      this.props.history.push('/confirma_agendamento')
    }
    
    e.preventDefault();
  }




  componentDidMount() {   
        this.setState({ loading: true });   

  }

  render() {

    return (
      <div>
        <Topo />
        <Container>
        <Row className="mt-2">
          <Col><Back /></Col>
            <Col className="text-center titulo">Plano</Col>    
            <Col />        
          </Row>        
     
          <style type="text/css">
            {`
                 .btn-disponivel {
                  background-color: #00cc8b;    
                  color:white;      
                  width:100%;       
                  height:70px;
                 }
                 .btn-ocupado {
                  background-color: #ff4d4d;
                  color:white;                 
                 }
                 #gridEspecialidades{
                  max-height: 75vh;
                  overflow-y: auto;
                 }
                 
               `}
          </style>
          {this.state.loading ? 
          ( 
          <Form onSubmit={this.handleSubmit} >
            <Form.Group className="mt-3">
            <Form.Control as="select" size="lg" value={this.state.forma} onChange={this.selecionaForma} >
              <option value="0">Particular ou Convênio</option>
              <option value="1">Convênio</option>
              <option value="2">Particular</option>
            </Form.Control>
            <br />


            {this.state.mostraConvenios ? ( 
            <Form.Control as="select" size="lg" value={this.state.convenio} onChange={this.selecionaConvenio}>
              <option>Selecione o Convênio</option>
              { this.state.convenios.map(convenio => <option key={convenio.cod_conv} value={convenio.cod_conv}>{convenio.convenio}</option>)}
            </Form.Control>
            ):(" ")}
            <br />     


             {this.state.mostraPlanos ? ( 
            <Form.Control as="select" size="lg" value={this.state.plano} onChange={this.selecionaPlano}>
              <option>Selecione o Plano</option>
              { this.state.planos.map(plano => <option key={plano.cod_plan} value={plano.cod_plan}>{plano.plano}</option>)}
            </Form.Control>
            ):(" ")}
            <br />  
            </Form.Group>

             <Button variant="success"  size="lg" block type="submit">
             Continuar
           </Button>
         </Form>
            ) : (<Spinner animation="border" className="loading" variant="success">
            <span className="sr-only">Aguarde...</span>
          </Spinner>)}


        </Container>
        <Rodape />
      </div>

    )
  }
}

export default Convenio;
