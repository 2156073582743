/* eslint-disable react/state-in-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Back from "./components/back";
import Topo from './components/topo';
import Rodape from './components/rodape';

const qs = require('qs');



class ConfirmaAgendamento extends Component {

      state = {
        nomeConvenio: [],
        nomePlano: [],
        dadosVaga: [],
        dadosPaciente: [],
        cadastro: '',
        idVaga: '',
        codpac: '',
        nome: '',
        cpf: '',
        nascimento: '',
        celular: '',
        email: '',
        data: '',
        resultado: ''
      }
  



  componentDidMount() {
    const convEscolhido = sessionStorage.getItem('@masterclinicapwa/convenio')
    axios.get(`https://mgabr.com/apiapp/endoserv/nomeConvenio.php?codconv=${convEscolhido}`)
      .then(res => {
        const nomeConvenio = res.data;
        this.setState({ nomeConvenio });
      });


    const planEscolhido = sessionStorage.getItem('@masterclinicapwa/plano')
    axios.get(`https://mgabr.com/apiapp/endoserv/nomePlano.php?codconv=${convEscolhido}&codplan=${planEscolhido}`)
      .then(res => {
        const nomePlano = res.data;
        this.setState({ nomePlano });
      });

    const idVaga = sessionStorage.getItem('@masterclinicapwa/hora')
    axios.get(`https://mgabr.com/apiapp/endoserv/dadosVaga.php?idvaga=${idVaga}`)
      .then(res => {
        const dadosVaga = res.data;
        console.log(`ID DA VAGA${idVaga}`);
        this.setState({ dadosVaga });
      });



          this.setState({ nome: sessionStorage.getItem('@masterclinicapwa/nome') });
          this.setState({ cpf:  sessionStorage.getItem('@masterclinicapwa/cpf') });
          this.setState({ nascimento: sessionStorage.getItem('@masterclinicapwa/nascimento') });
          this.setState({ celular: sessionStorage.getItem('@masterclinicapwa/celular')});
          this.setState({ email:  sessionStorage.getItem('@masterclinicapwa/email')});

        }

  handleSubmit = (e) => {
    const idVaga = sessionStorage.getItem('@masterclinicapwa/hora')
    const celular = sessionStorage.getItem('@masterclinicapwa/celular')
    const planoEscolhido = sessionStorage.getItem('@masterclinicapwa/plano')
    const convEscolhido = sessionStorage.getItem('@masterclinicapwa/convenio')

    const data = qs.stringify({
      'id': idVaga,
      'codpac': '999999',
      'nome': this.state.nome,
      'doc': this.state.cpf.replace(/[^\d]+/g, ''),
      'dt_nas': this.state.nascimento.replace(/[^\d]+/g, ''),
      'email': this.state.email,
      'cel': celular.replace(/[^\d]+/g, ''),
      'conv': convEscolhido,
      'plano': planoEscolhido
    });
    const config = {
      method: 'post',
      url: 'https://mgabr.com/apiapp/endoserv/updateReact.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data
    };

    axios(config)    
    .then(res => {
        const idVaga = sessionStorage.getItem('@masterclinicapwa/hora')
        axios.get(`https://mgabr.com/apiapp/endoserv/dadosVaga.php?idvaga=${idVaga}`)
          .then(res => {
            const dadosVaga = res.data;
            this.setState({ dadosVaga }); 
            const celular = sessionStorage.getItem('@masterclinicapwa/celular')
            const mensagem = 
`SR(A).: ${dadosVaga[0].nome}
Sua solicitação de agendamento foi realizada com sucesso, aguarde a confirmação!

🗓️ DIA: ${sessionStorage.getItem('@masterclinicapwa/dataMostra')}
⏰ HORÁRIO: ${dadosVaga[0].hora.slice(0,2)+':'+dadosVaga[0].hora.slice(2,4)}
👨‍⚕️ DR(A).: ${dadosVaga[0].prof}
🩺 ESPECIALIDADE: ${sessionStorage.getItem('@masterclinicapwa/especialidade')}

⚠️ SE NÃO PUDER VIR À CONSULTA, POR FAVOR NOS AVISE POR PARA QUE POSSAMOS DISPONIBILIZAR O HORÁRIO PARA OUTROS CLIENTES.`
            
    
             const data1 = qs.stringify({
              'numero': celular.replace(/[^\d]+/g,''),
              'mensagem': mensagem,
              'email': 1,           
               });
               const config1 = {
                 method: 'post',
                 url: 'https://mgabr.com/apiapp/endoserv/enviawhats.php',
                 headers: { 
                   'Content-Type': 'application/x-www-form-urlencoded'
                 },
                 data : data1
               };
               
               axios(config1)
               .then(function (response) {
                 console.log(JSON.stringify(response.data));        



               })
               .catch(function (error) {
                 console.log(error);
               });
          });

        this.props.history.push('/sucesso')
       })
      .catch(function () {
        alert('Erro ao tentar realizar o agendamento')
      });
    e.preventDefault();
  }
  


  render() {
    return (
      <div>
        <Topo />
        <Container>
          <Row className="mt-2 mb-3">
            <Col><Back /></Col>
            <Col className="text-center titulo">Confirmar</Col>
            <Col />
          </Row>


          <style type="text/css">
            {`
                .container{                  
                  overflow-y: auto;
                  height: 100vh;
                }
                 
                 .form-group{
                  margin-bottom: 0px !important;
                } 
                .btn{margin-top:10px !important}
                .form-control{
                  height: 2em !important
                }
              
                label{margin-bottom: 0px !important}
                .font08{font-size:0.8rem}
                .font09{font-size:0.9rem}
  
               `}
          </style>



         
              <div className="form">
                <Form onSubmit={this.handleSubmit} >
                <Row className="mt-2 bg-white justify-content-md-center">
                    <Col sm={6}>
                      <Form.Group controlId="cod_pac">                       
                          <Form.Control name="codpac" type="hidden" value={sessionStorage.getItem('@masterclinicapwa/codpac')} disabled />
                      </Form.Group>
                      <Form.Group controlId="nome">
                        <Form.Label>Nome</Form.Label>
                         <Form.Control name="nome" type="text" value={sessionStorage.getItem('@masterclinicapwa/nome')} disabled />
                      </Form.Group>
                      <Form.Group controlId="cpf">
                        <Form.Label>CPF</Form.Label>
                            <Form.Control name="cpf" type="text" value={sessionStorage.getItem('@masterclinicapwa/cpf')} disabled />
                      </Form.Group>
                      <Form.Group controlId="nascimento">
                        <Form.Label>Data de Nascimento</Form.Label>
                          <Form.Control  name="nascimento" type="text" value={sessionStorage.getItem('@masterclinicapwa/nascimento')} disabled />
                      </Form.Group>
                      <Form.Group controlId="celular">
                        <Form.Label>Celular</Form.Label>
                          <Form.Control  name="celular" type="text" value={sessionStorage.getItem('@masterclinicapwa/celular')} />
                      </Form.Group>

                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control  name="email" type="text" value={sessionStorage.getItem('@masterclinicapwa/email')} disabled />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="celular">
                        <Form.Label>Convenio</Form.Label>
                        {this.state.nomeConvenio.map(nome =><Form.Control key={nome.id}  type="text" placeholder="" value={nome.convenio} disabled />)}
                      </Form.Group>
                        {this.state.nomePlano.map(nome => <Form.Group controlId="telefone">
                        <Form.Label>Plano</Form.Label>
                        <Form.Control key={nome.id}  name="plano" type="text" placeholder="" value={nome.plano} disabled />
                      </Form.Group>)}
                      <Form.Group controlId="profissional">
                        <Form.Label>Profissional</Form.Label>
                        {this.state.dadosVaga.map(vaga => <Form.Control type="text" value={vaga.prof} disabled />)}
                      </Form.Group>
                      <Form.Group controlId="especialidade">
                        <Form.Label>Especialidade</Form.Label>
                        <Form.Control type="text" value={sessionStorage.getItem('@masterclinicapwa/especialidade')} disabled />
                      </Form.Group>
                      <Row>
                        <Col>
                      <Form.Group controlId="especialidade">
                        <Form.Label>Data</Form.Label>
                        <Form.Control type="text"  value={sessionStorage.getItem('@masterclinicapwa/dataMostra')} disabled />
                      </Form.Group>
                      </Col>
                      <Col>
                      <Form.Group controlId="especialidade">
                        <Form.Label>Hora</Form.Label>
                        {this.state.dadosVaga.map(vaga => <Form.Control key={vaga.id} type="text"  value={vaga.hora.slice(0, 2) + ":" + vaga.hora.slice(2, 4)} disabled />)}
                      </Form.Group>
                      </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Button constiant="success" size="lg" block type="submit">
                    Solicitar Agendamento
                  </Button>
                </Form>
              </div>
           
        </Container>
        <Rodape />
      </div>
    )
  }
}

export default ConfirmaAgendamento;
