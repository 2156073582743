/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';


// eslint-disable-next-line react/prefer-stateless-function
class InicioPaciente extends Component {

  render() {
    return (
      <div>
        <Topo />
        <Container>
        <Row>    
        <Col className="text-center titulo mt-2">Bem vindo, o que deseja?</Col>
        </Row>

          <style type="text/css">
            {`
                .btn{margin-top:10px !important}
                .form-control{margin-bottom:0px !important; padding:0px !important}
            `}
          </style>

    
          
          <Row className="mt-2 bg-white justify-content-md-center">
          <Col className="somenteDesk p-4">   
          <Image src={'appInicio.jpg'} className="imagem" />
          </Col>

          <Col> 
          <Link to='/loginPaciente'>
            <Button variant="outline-success" size="lg" block>
              Realizar um Agendamento
          </Button>
          </Link>
          <Link to='/laudos'>
            <Button variant="outline-success" size="lg" block>
              Consultar Laudos
          </Button>
          </Link> 
          </Col>
          </Row>

         

        </Container>
        <Rodape />
      </div>

    )
  }
}

export default InicioPaciente
