import React, { Component } from 'react';
import { Container, ListGroup, Button, Spinner, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Topo from './components/topo';
import Rodape from './components/rodape';


class Especialidades extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    especialidades: [],
    loading: false,
  }

    componentDidMount() {
    
    axios.get('https://mgabr.com/apiapp/endoserv/apiespecialidades.php')

      .then(res => {
        const especialidades = res.data;

        this.setState({ especialidades });
        this.setState({ loading: true });
      });
  }

  handleClick = (e) => { 

    sessionStorage.setItem('@masterclinicapwa/especialidade', e);
    sessionStorage.setItem('@masterclinicapwa/voltar', '/especialidades')
    this.props.history.push('/profissionais')
  }

  render() {

    return (
      <div>
        <style type="text/css">
            {`
             #gridEspecialidades{
              max-height: 75vh;
              overflow-y: auto;
              }  
            `}
        </style>

      <Topo />
        <Container>
          <Row className="mt-2">
            <Col className="text-center titulo">Especialidade</Col>            
          </Row>
          {this.state.loading ? (<ListGroup  id="gridEspecialidades">

            { this.state.especialidades.map(especialidade => <ListGroup.Item key={especialidade.espe}><Button className="buttonEspecialidade" value={especialidade.espe.split("/",1)} variant="disponivel" onClick={e=>this.handleClick(e.target.value)}>{especialidade.espe}</Button></ListGroup.Item>)}
          </ListGroup>) : (<Spinner animation="border" className="loading" variant="success">
            <span className="sr-only">Aguarde...</span>
          </Spinner>)}


        </Container>
        <Rodape />
      </div>

    )
  }
}

export default Especialidades;
