/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { Navbar, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Topo extends Component {

  handleClick = () => {
    sessionStorage.clear();
  }

  render() {
    return (
      <div>
        <Navbar className="topo shadow">           
        <Navbar.Brand href="/"> <Image src={'logo.png'} className="imageTopo center" /></Navbar.Brand>
          <Nav className="mr-auto somenteDesk">  
          <Nav.Link><Link to='/laudos' className="nav__link_topo" >Laudos</Link></Nav.Link>        
          </Nav>
        
        </Navbar>

      </div>
    );
  }
}
export default Topo;